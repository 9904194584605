import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import GuestGuard from 'src/components/GuestGuard';
// import AuthGuard from 'src/components/AuthGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    layout: DashboardLayout,
    path: '/',
    component: () => <Redirect to="/civil-services-exam/app/threads/browse" />
  },
  // {
  //   exact: true,
  //   layout: DashboardLayout,
  //   path: '/test',
  //   component: () => import('src/test')
  // },
  {
    exact: true,
    layout: DashboardLayout,
    path: '/:exam_slug',
    // component: lazy(() => import('src/views/project/OverviewView'))
    component: () => <Redirect to="/civil-services-exam/app/threads/browse" />
  },
  {    
    exact: true,
    guard: GuestGuard,
    path: '/:exam_slug/forgotpassword',
    component: lazy(() => import('src/views/auth/Login/forgotPassword.js'))
  },
  {
    exact: true,
    path: '/:exam_slug/login',
    component: lazy(() => import('src/views/auth/Login'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/:exam_slug/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/:exam_slug/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/:exam_slug/session/start/:activity_id',
    component: lazy(() => import('src/views/zoom/StartMeeting'))
  },
  {
    exact: true,
    path: '/:exam_slug/session/join/:activity_id',
    component: lazy(() => import('src/views/zoom/JoinMeeting'))
  },
  {
    exact: true,
    path: '/:exam_slug/open-session/join/:activity_id',
    component: lazy(() => import('src/views/zoom/JoinOpenMeeting'))
  },
  {
    path: '/:exam_slug/app',
    // guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/:exam_slug/app/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: [
          '/:exam_slug/app/chat/new',
          '/:exam_slug/app/chat/:threadKey'
        ],
        component: lazy(() => import('src/views/chat/ChatView'))
      },
      {
        exact: true,
        path: '/:exam_slug/app/chat',
        component: () => <Redirect to="/app/chat/new" />
      },
      // {
      //   exact: true,
      //   path: '/:exam_slug/app/user/overview',
      //   component: lazy(() => import('src/views/project/OverviewView'))
      // },
      {
        exact: true,
        path: '/:exam_slug/app/threads/browse',
        component: lazy(() => import('src/views/project/ProjectBrowseView'))
      },
      {
        exact: true,
        path: '/:exam_slug/app/threads/create',
        component: lazy(() => import('src/views/project/ProjectCreateView'))
      },
      {
        exact: true,
        path: '/:exam_slug/app/threads/:id',
        component: lazy(() => import('src/views/project/ProjectDetailsView'))
      },
      {
        exact: true,
        path: '/:exam_slug/app/community/browse',
        component: lazy(() => import('src/views/communities/CommunityBrowseView'))
      },
      {
        exact: true,
        path: '/:exam_slug/app/community/',
        component: lazy(() => import('src/views/communities/CommunityDetailsView'))
      },
      // {
      //   exact: true,
      //   path: '/app/projects',
      //   component: () => <Redirect to="/app/projects/browse" />
      // },
      {
        exact: true,
        path: '/:exam_slug/app/social/feed',
        component: lazy(() => import('src/views/social/FeedView'))
      },
      {
        exact: true,
        path: '/:exam_slug/app/social/profile',
        component: lazy(() => import('src/views/social/ProfileView'))
      },
      {
        exact: true,
        path: '/:exam_slug/app/social/profile/:id',
        component: lazy(() => import('src/views/social/ProfileView'))
      },
      {
        exact: true,
        path: '/:exam_slug/app/acitivity/create',
        component: lazy(() => import('src/views/Activity/ActivityCreateView/CreateActivity'))
      },
      {
        exact: true,
        path: '/:exam_slug/app/acitivity/create-new',
        component: lazy(() => import('src/views/Activity/ActivityCreateView/'))
      },
      {
        exact: true,
        path: '/:exam_slug/app/acitivity/edit/:activity_id',
        component: lazy(() => import('src/views/Activity/ActivityCreateView/UpdateActivity'))
      },
      {
        exact: true,
        path: '/:exam_slug/app/mentor-hub',
        component: lazy(() => import('src/views/Activity/FeedView/index'))
      },
      {
        exact: true,
        path: '/:exam_slug/app/mentor-hub/:id',
        component: lazy(() => import('src/views/Activity/ActivityDetails/index'))
      },
      {
        exact: true,
        path: '/:exam_slug/app/session/start/:activity_id',
        component: lazy(() => import('src/views/zoom/StartMeeting'))
      },
      {
        exact: true,
        path: '/:exam_slug/app/session/join/:activity_id',
        component: lazy(() => import('src/views/zoom/JoinMeeting'))
      },     
      {
        exact: true,
        path: '/:exam_slug/app/open-session/join/:activity_id',
        component: lazy(() => import('src/views/zoom/JoinOpenMeeting'))
      },
      {
        exact: true,
        path: '/:exam_slug/app/social',
        component: () => <Redirect to="/app/social/profile" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  
];

export default routes;
