import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    loading: false,
    error: null,
    success: false,
    userData:[],
};

export const UsersSlice=createSlice({
    name:"userData",
    initialState,
    reducers:{
        USER_FETCH_START:(state)=>{
            state.loading = true;
            state.error = null;
            state.success = false;
        },
        USER_FETCH_SUCCESS:(state,action)=>{
            state.loading = false;
            state.error = null;
            state.success = true;
            state.postData=action.payload;
        },        
        USER_FETCH_FAIL:(state,action)=>{
            state.loading = false;
            state.error = action.payload;
            state.success = false;
        }      
    }
})

export const {
    USER_FETCH_START,USER_FETCH_SUCCESS,USER_FETCH_FAIL,
}=UsersSlice.actions;

export default UsersSlice.reducer;