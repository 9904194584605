import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as notificationReducer } from 'src/slices/notification';
import AuthReducer from 'src/slices/AuthSlice';
import PostReducer from 'src/slices/PostSlice';
import ThreadReducer from 'src/slices/ThreadSlice';
import UserReducer from 'src/slices/UsersSlice';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  form: formReducer,
  notifications: notificationReducer,
  authData:AuthReducer,
  postData:PostReducer,
  threadData:ThreadReducer,
  userData:UserReducer,
});

export default rootReducer;
