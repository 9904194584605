import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
  Button
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import Account from './Account';
import Contacts from './Contacts';
import Notifications from './Notifications';
import Search from './Search';
import Settings from './Settings';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default
    } : {}
  },
  toolbar: {
    minHeight: 64,
    borderRadius:"0px !important",
    background:"linear-gradient(0deg, rgb(249, 249, 249) 0%, rgb(57, 73, 171) 85%) !important"
  }
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const {authData}=useSelector((state)=>state.authData);
  const {exam_slug}=useParams();
  // console.log("hello from top bar");
  
  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
      
    >
      <Toolbar 
      className={classes.toolbar}
      >
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <a href="https://gsscore.com/">
            <Logo />
          </a>
        </Hidden>
        <Box
          ml={2}
          flexGrow={1}
        />
        {/* <Search /> */}
        {/* {
          authData.userId>0?
          <><Contacts />
          <Notifications />
          </>:''
        } */}
        <Settings />
        <Box ml={2}>
        {
          authData.userId>0?
          <Account />
          :
          <Button
            className={classes.action}
            variant="contained"
            href={`/${exam_slug}/login`}
          >
            LOGIN/REGISTER
          </Button>
        }
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
