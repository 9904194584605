import React, { useState } from 'react';
import { NavLink as RouterLink,Link as domLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Collapse,
  ListItem,
  makeStyles
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    // color: theme.palette.text.dark,
    color: '#000',
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  buttonLeaf: {
    // color: theme.palette.text.secondary,
    color: "#000",
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    '&:hover': {
      backgroundColor:"#5672cb",
      color:"#fff"
    }
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    backgroundColor:"#779efd42",
    color: theme.palette.secondary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.secondary.main
      // color: "#000"
    }
  },

}));

const NavItem = ({
  children,
  className,
  depth,
  href,
  type,
  icon: Icon,
  info: Info,
  open: openProp,
  title,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(openProp);
  const {exam_slug}=useParams();
  const {authData}=useSelector((state)=>state.authData);
  // console.log(authData.user_type);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  
  if(!(authData.userId && authData) && title==="Profile"){
    return false;
  }
  if(!(authData.user_type=='3' && authData) && title==="Create Acitivity"){
    return false;
  }
  
  let paddingLeft = 8;
  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }
  const style = { paddingLeft };

  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          className={classes.button}
          onClick={handleToggle}
          style={style}
        >
          {Icon && (
            <Icon
              className={classes.icon}
              size="20"
            />
          )}
          <span className={classes.title}>
            {title}
          </span>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
        <Collapse in={open}>
          {children}
        </Collapse>
      </ListItem>
    );
  }
  
  return (
    <ListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
      {...rest}
    >
      {type==='offering'?(
        <a href={`${href}`}>
            <Button
            activeClassName={classes.active}
            
            className={clsx(classes.buttonLeaf, `depth-${depth}`)}
           
            exact
            style={style}        
             
          >
            {Icon && (
              <Icon
                className={classes.icon}
                size="20"
              />
            )}
            <span className={classes.title}>
              {title}
            </span>
            {Info && <Info />}
          </Button>
        </a>
      ):(
        <Button
        activeClassName={classes.active}
        className={clsx(classes.buttonLeaf, `depth-${depth}`)}
        component={RouterLink}
        exact
        // onClick={()=> window.location.href=href}
        style={style}        
        to={`/${exam_slug}${href}`}
      >
        {Icon && (
          <Icon
            className={classes.icon}
            size="20"
          />
        )}
        <span className={classes.title}>
          {title}
        </span>
        {Info && <Info />}
      </Button>
      )}
    </ListItem>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
  open: false
};


export default NavItem;
