import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    loading: false,
    error: null,
    success: false,
    threadData:[],
};

export const ThreadSlice=createSlice({
    name:"threadData",
    initialState,
    reducers:{
        THREAD_FETCH_START:(state)=>{
            state.loading = true;
            state.error = null;
            state.success = false;
        },
        THREAD_FETCH_SUCCESS:(state,action)=>{
            state.loading = false;
            state.error = null;
            state.success = true;
            state.threadData=action.payload;
        },        
        THREAD_FETCH_FAIL:(state,action)=>{
            state.loading = false;
            state.error = action.payload;
            state.success = false;
        },  
        THREAD_CREATE_START:(state)=>{
            state.loading = true;
            state.error = null;
            state.success = false;
        },
        THREAD_CREATE_SUCCESS:(state,action)=>{
            state.loading = false;
            state.error = null;
            state.success = true;
            state.threadData = [action.payload, ...state.threadData];
        },        
        THREAD_CREATE_FAIL:(state,action)=>{
            state.loading = false;
            state.error = action.payload;
            state.success = false;
        },   
        THREAD_REPLY_CREATE_START:(state)=>{
            state.loading = true;
            state.error = null;
            state.success = false;
        },
        THREAD_REPLY_CREATE_SUCCESS:(state,action)=>{
            let threadsList=[...state.threadData];
            const threadIndex  = threadsList.findIndex((item) => item._id === action.payload.threadId);
            if(threadIndex >=0){
                let updatedThread = { ...threadsList[threadIndex] };  
                updatedThread.ThreadReply = [action.payload, ...updatedThread.ThreadReply];
                threadsList[threadIndex] = updatedThread;     
            }
            // console.log("thread",thread);
            state.loading = false;
            state.error = null;
            state.success = true;
            state.threadData = threadsList
        },        
        THREAD_REPLY_CREATE_FAIL:(state,action)=>{
            state.loading = false;
            state.error = action.payload;
            state.success = false;
        },   
        THREAD_LIKE_CREATE_START:(state)=>{
            state.loading = true;
            state.error = null;
            state.success = false;
        },
        THREAD_LIKE_CREATE_SUCCESS:(state,action)=>{
            let threadList=[...state.threadData];            
            const threadIndex  = threadList.findIndex((item) => item._id === action.payload._id);
            if(threadIndex >=0){
                let updatedThread = { ...threadList[threadIndex] };  
                updatedThread.likes = action.payload.likes;
                threadList[threadIndex] = updatedThread;     
            }
            state.loading = false;
            state.error = null;
            state.success = true;
            state.threadData = threadList
        },        
        THREAD_LIKE_CREATE_FAIL:(state,action)=>{
            state.loading = false;
            state.error = action.payload;
            state.success = false;
        },     
    }
})

export const {
    THREAD_FETCH_START,THREAD_FETCH_SUCCESS,THREAD_FETCH_FAIL,
    THREAD_CREATE_START,THREAD_CREATE_SUCCESS,THREAD_CREATE_FAIL,
    THREAD_REPLY_CREATE_START,THREAD_REPLY_CREATE_SUCCESS,THREAD_REPLY_CREATE_FAIL,
    THREAD_LIKE_CREATE_START,THREAD_LIKE_CREATE_SUCCESS,THREAD_LIKE_CREATE_FAIL
}=ThreadSlice.actions;

export default ThreadSlice.reducer;