import React from 'react';

const Logo = (props) => {
  return (
    <img
      style={{ width: '5pc', }}
      alt="Logo"
      className='logo'
      // src="/static/gsscore_logo.png"
      // src="https://gsscore.com/assets/amir/img/new_gsscore_logo.jpeg"
      src="https://gsscore.com/assets/amir/img/Pls_logo.png"

      {...props}
    />
  );
}

export default Logo;
