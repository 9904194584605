import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    loading: false,
    error: null,
    success: false,
    postData:[],
};

export const PostSlice=createSlice({
    name:"postData",
    initialState,
    reducers:{
        POST_FETCH_START:(state)=>{
            state.loading = true;
            state.error = null;
            state.success = false;
        },
        POST_FETCH_SUCCESS:(state,action)=>{            
            state.loading = false;
            state.error = null;
            state.success = true;
            state.postData=action.payload;
        },        
        POST_FETCH_FAIL:(state,action)=>{
            state.loading = false;
            state.error = action.payload;
            state.success = false;
        },          
        POST_CREATE_START:(state)=>{
            state.loading = true;
            state.error = null;
            state.success = false;
        },
        POST_CREATE_SUCCESS:(state,action)=>{
            // console.log(state);
            
            state.loading = false;
            state.error = null;
            state.success = true;
            state.postData = [action.payload, ...state.postData];
        },        
        POST_CREATE_FAIL:(state,action)=>{
            state.loading = false;
            state.error = action.payload;
            state.success = false;
        }, 
        POST_COMMENT_CREATE_START:(state)=>{
            state.loading = true;
            state.error = null;
            state.success = false;
        },
        POST_COMMENT_CREATE_SUCCESS:(state,action)=>{
            let postList=[...state.postData];
            const postIndex  = postList.findIndex((item) => item._id === action.payload.postId);
            if(postIndex >=0){
                let updatedPost = { ...postList[postIndex] };  
                updatedPost.comments = [action.payload, ...updatedPost.comments];
                postList[postIndex] = updatedPost;     
            }
            // console.log("thread",thread);
            state.loading = false;
            state.error = null;
            state.success = true;
            state.postData = postList
        },        
        POST_COMMENT_CREATE_FAIL:(state,action)=>{
            state.loading = false;
            state.error = action.payload;
            state.success = false;
        },
        POST_LIKE_CREATE_START:(state)=>{
            state.loading = true;
            state.error = null;
            state.success = false;
        },
        POST_LIKE_CREATE_SUCCESS:(state,action)=>{
            let postList=[...state.postData];            
            const postIndex  = postList.findIndex((item) => item._id === action.payload._id);
            if(postIndex >=0){
                let updatedPost = { ...postList[postIndex] };  
                updatedPost.likes = action.payload.likes;
                postList[postIndex] = updatedPost;     
            }
            state.loading = false;
            state.error = null;
            state.success = true;
            state.postData = postList
        },        
        POST_LIKE_CREATE_FAIL:(state,action)=>{
            state.loading = false;
            state.error = action.payload;
            state.success = false;
        },
    }
})

export const {
    POST_FETCH_START,POST_FETCH_SUCCESS,POST_FETCH_FAIL,
    POST_CREATE_START,POST_CREATE_SUCCESS,POST_CREATE_FAIL,
    POST_COMMENT_CREATE_START,POST_COMMENT_CREATE_SUCCESS,POST_COMMENT_CREATE_FAIL,
    POST_LIKE_CREATE_START,POST_LIKE_CREATE_SUCCESS,POST_LIKE_CREATE_FAIL,
    
}=PostSlice.actions;

export default PostSlice.reducer;